<template>
  <div class="prizecon">
    <!-- 邀请记录 -->
    <div class="interList">
      <div class="name">
        <span>{{$t('invite.invite_users')}}</span>
        <span>{{$t('invite.time')}}</span>
      </div>
      <div class="list">
        <div class="l_item" v-for="(item, index) in list" :key="index">
          <div>{{ item.vipid }}</div>
          <div>{{ item.newdate }}</div>
        </div>
      </div>
    </div>
    <m-empty v-if="!list.length"></m-empty>
  </div>
</template>

<script>
import mEmpty from '@/components/Empty.vue'
import { usersaleApi } from '@/api/my'
export default {
  components: {
    mEmpty
  },
  data () {
    return {
      list: [],
      pageindex: 1,
      pagesize: 5, // 每页条数
      page: 0 // 总条数
    }
  },
  methods: {
    async getInvite () {
      const res = await usersaleApi({
        action: 'invitation',
        pageindex: this.pageindex,
        pagesize: this.pagesize
      })
      if (this.pageindex === 1) {
        this.page = Math.ceil(res.totalCount / this.pagesize)
      }
      this.list = res.invitation
    }
  },
  mounted () {
    this.getInvite()
  }
}
</script>

<style lang="scss" scoped>
.prizecon {
  .title {
    text-align: center;
    margin-bottom: 50px;
    h5 {
      font-size: 14px;
      margin-bottom: 5px;
    }
    p {
      font-size: 12px;
    }
  }
}
.interList {
  max-width: 1060px;
  .name {
    display: flex;
    border-bottom: 1px solid #ccc;
    span {
      display: inline-block;
      width: 166px;
      margin-bottom: 5px;
    }
    & > span:nth-of-type(1) {
      width: 530px;
    }
  }
  .list {
    .l_item {
      display: flex;
      border-bottom: 1px solid #ccc;
      padding: 16px 0px;
      & > div {
        width: 166px;
        font-size: 14px;
      }
      & > div:nth-of-type(1) {
        width: 515px;
        padding-left: 15px;
      }
    }
  }
}
</style>
